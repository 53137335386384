
<template>
<div>

  <!-- <div>{{name}}</div> -->
  <ul>
    <li v-for="(item,ind) in movies" v-bind:key="ind"  >{{ item.name }}</li>
  </ul>
  <button @click="previousPage">previous</button>
  <button @click="nextPage">next</button>


  
  </div>
</template>
<script>
// import { ref } from 'vue'
import useMovies from './useMovies'
import { ref } from "@vue/composition-api";
export default ({
  name: 'Movie',
  // setup() {
  //       const name = ref ("Shawn");

  //       function save() {
  //           name.value = name.value + " saved";
  //           alert(`Name: ${name.value}`);
  //       }
  //       return { name, save };
  //   },

  setup () {
    let page = ref(1);
    // let movies2 = [{ "name": "Iron Man", "owner": "Mavel Studio" }, { "name": "Justice Leauge", "owner": "Warner Brother" }];

    const { movies, getMovies } = useMovies(page);
    console.log('getMovies' ,getMovies);
    console.log('page' ,page.value);
    function nextPage () {
      page++
    }
    function previousPage () {
      page--
    }
    return {
      movies,
      nextPage,
      previousPage
    }
  }
})

</script>
