// src/composables/useMovies.js
// import { fetchMovies } from '@/api/movies'
// import { ref, onMounted, watch } from 'vue'
import { ref, onMounted, watch } from "@vue/composition-api";

export const mov = [{ "name": "Iron Man", "owner": "Mavel Studio" }, { "name": "Justice Leauge", "owner": "Warner Brother" }];

export default function useMovies(page) {
  const movies = ref([])
  const getMovies = async () => {
    movies.value = mov;
  }

  onMounted(getMovies)
  watch(page, getMovies)
  return {
    movies,
    getMovies
  }
}
